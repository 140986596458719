<template>
   <main>
      <!-- breadcrumb area start -->
      <section class="breadcrumb__area pt-30 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-lg-8 offset-lg-2">
                  <div class="breadcrumb__wrapper">
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb mb-0">
                           <li class="breadcrumb-item"><router-link to="/"><i class="far fa-home color-theme me-2"></i>Home</router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Assistência Técnica</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>

		<!-- map area start -->
      <section class="map__area pt-10 pb-30 grey-bg">
         <div class="container">
            <highcharts :constructor-type="'mapChart'" :options="chartOptions" class="d-flex justify-content-center" />
         </div>
      </section>

		<!-- select map area start -->
      <section class="select__map__area pt-20 pb-20">
         <div class="container">
            <div class="row m-0 justify-content-center justify-content-md-start">
               <div class="wpx-200 px-2 d-flex d-md-block align-items-center">
                  <label class="weight-500 text-uppercase text-dark font-12 me-3 me-md-0">Estado</label>
                  <v-select title="Desktop" :options="estados" v-model="pesquisa.estado" placeholder="Selecione" @input="getCidades(pesquisa.estado, true)">
                     <template v-slot:no-options>Sem resultados</template>
                  </v-select>
                  <select title="Mobile" class="form-control" v-model="pesquisa.estado" @change="getCidades(pesquisa.estado, true)">
                     <option value="" disabled selected>Selecione</option>
                     <option v-for="(option, index) in estados" :key="index" :value="option">{{ option }}</option>
                  </select>
               </div>
               <div class="wpx-350 px-2 d-flex d-md-block align-items-center mt-2 mt-md-0">
                  <label class="weight-500 text-uppercase text-dark font-12 me-3 me-md-0">Cidade</label>
                  <v-select title="Desktop" :options="resultado.cidades" v-model="pesquisa.cidade" placeholder="Selecione" label="nome" @input="getAssistencias">
                     <template v-slot:no-options>Sem resultados</template>
                  </v-select>
                  <select title="Mobile" class="form-control" v-model="pesquisa.cidade" @change="getAssistencias">
                     <option value="" disabled selected>Selecione</option>
                     <option v-for="(option, index) in resultado.cidades" :key="index" :value="option">{{ option.nome }}</option>
                  </select>
               </div>
            </div>
         </div>
      </section>

		<!-- result map area start -->
      <section class="result__map__area pt-20 pb-200 grey-bg">
         <div class="container">
            <div class="row">
               <div class="col-12 pt-150 text-center" v-if="pesquisa.estado == null">Selecione um estado</div>
               <div class="col-12 pt-150 text-center" v-else-if="pesquisa.cidade == null">Selecione uma cidade</div>
               <div class="col-12 pt-150 text-center" v-else-if="resultado.pesquisa.length == 0">Nenhum resultado encontrado</div>
               <div class="col-12 pb-3" v-else>{{ resultado.pesquisa.length }} resultado(s) encontrado(s)</div>
               <div class="col-12" v-if="resultado.pesquisa.length > 0">
                  <div class="card bg-secondary rounded-0 border-0 mb-0">
                     <div class="card-body py-12 px-2">
                        <div class="row m-0 align-items-center text-dark weight-500 text-uppercase font-12">
                           <div class="wpx-200 px-1 d-none d-xxl-block">Cidade</div>
                           <div class="col px-1 d-none d-md-block">Nome</div>
                           <div class="wpx-250 px-1">Endereço</div>
                           <div class="wpx-150 px-1 d-none d-lg-block">Telefone</div>
                           <div class="wpx-200 px-1 d-none d-xl-block">Email</div>
                           <div class="wpx-200 px-1 d-none d-sm-block">Especializações</div>
                        </div>
                     </div>
                  </div>
               </div>

               <revenda v-for="(revenda, index) in resultado.pesquisa" :key="index" :revenda="revenda" :index="index" :especializacoes="true" />
            </div>
         </div>
      </section>
   </main>
</template>

<script>

import Vue from 'vue'
import $ from 'jquery'
import { mapState } from 'vuex'
import revenda from '@/components/revendas/Revenda.vue'
import brasilMap from "@highcharts/map-collection/countries/br/br-all.topo.json";

export default {
   name: 'Assistência_Técnica',
   data: function () {
      return {
         estados: ['AC', 'AL', 'AM', 'AP', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MG', 'MS', 'MT', 'PA', 'PB', 'PE', 'PI', 'PR', 'RJ', 'RN', 'RO', 'RR', 'RS', 'SC', 'SE', 'SP', 'TO'],
         pesquisa: {'estado': null, 'cidade': null},
         resultado: {'cidades': [], 'pesquisa': []},
         chartInstance: null,
         chartOptions: {
            title: null,
            chart: {
               map: brasilMap,
               width: $(".map__area .container").width(),
               height: $(".map__area .container").width(),
               backgroundColor: '#f9f9f9',
               animation: false,
               events: {
                  load: (event) => {
                     this.chartInstance = event.target;
                  }
               }
            },
            mapNavigation: {enabled: false},
            legend: {enabled: false},
            credits: {enabled: false},
            plotOptions: {
               series: {
                  point: {
                     events: {
                        select: (event) => {
                           if (event.accumulate) {
                              let selecionados = event.target.series.chart.getSelectedPoints();
                              for (var i = 0; i < selecionados.length; i++) {
                                 selecionados[i].select(false, true);
                              }
                           }

                           this.pesquisa.estado = event.target.properties['postal-code'];
                           this.pesquisa.cidade = null;
                           
                           document.querySelector(".result__map__area").scrollIntoView();
                           this.getCidades(this.pesquisa.estado, false)
                        },
                        mouseOver: (event) => {
                           const chart = event.target.series.chart;

                           if (!chart.lbl) {
                              chart.lbl = chart.renderer.label('').attr({
                                 padding: 10,
                                 r: 10,
                                 x: event.target.plotX,
                                 y: event.target.plotY - 60,
                                 zIndex: 999,
                                 fill: '#5cbd0e'
                              }).css({
                                 color: '#fff',
                                 fontSize: 16,
                                 fontWeight: 600
                              }).add();
                           }

                           chart.lbl.show().attr({
                              text: event.target.properties['postal-code'],
                              x: event.target.plotX,
                              y: event.target.plotY - 60
                           });
                        },
                        mouseOut: (event) => {
                           const chart = event.target.series.chart;

                           if (chart.lbl) {
                              chart.lbl.hide();
                           }
                        }
                     }
                  }
               }
            },
            tooltip: {enabled: false},
            series: [{
               dataLabels: {enabled: false, format: "{point.properties.postal-code}"},
               cursor: 'pointer',
               allowPointSelect: true,
               color: '#e75023',
               states: {
                  hover: {
                     color: '#14397f',
                     borderColor: '#ebebeb'
                  },
                  select: {
                     color: '#14397f',
                     borderColor: '#ebebeb'
                  }
               },
               data: [
                  ['br-sp', 0], ['br-ma', 0], ['br-pa', 0], ['br-sc', 0], ['br-ba', 0], ['br-ap', 0], ['br-ms', 0], ['br-mg', 0], ['br-go', 0], ['br-rs', 0], 
                  ['br-to', 0], ['br-pi', 0], ['br-al', 0], ['br-pb', 0], ['br-ce', 0], ['br-se', 0], ['br-rr', 0], ['br-pe', 0], ['br-pr', 0], ['br-es', 0],
                  ['br-rj', 0], ['br-rn', 0], ['br-am', 0], ['br-mt', 0], ['br-df', 0], ['br-ac', 0], ['br-ro', 0]
               ]
            }]
         }
      }
   },
   computed: {
		... mapState({
			urlRest: state => state.urlRest
		})
	},
   components: {
      revenda
   },
   methods: {
      getCidades : function (estado, resetMap) {
         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)
         this.resultado.pesquisa = [];
         this.pesquisa.cidade = null;

         if (estado) {
            this.pesquisa.estado = estado;
         }

         if (resetMap && this.chartInstance) {
            let selecionados = this.chartInstance.getSelectedPoints()
            
            for (var i = 0; i < selecionados.length; i++) {
               selecionados[i].select(false, true);
            }

            try {
               this.chartInstance.series[0].data[this.chartInstance.series[0].data.map(uf => uf.properties['postal-code']).indexOf(this.pesquisa.estado)].select()
            } catch {
               console.error('Erro ao selecionar estado')
            }
         }

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getCidades',
            params: {
               estado: this.pesquisa.estado,
               filtro: 'Assistências'
            }
			}).then(response => {
            this.resultado.cidades = response.data

			}).catch(function (error) {
				if (error.response != undefined) {
               ref.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
				} else {
					ref.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      },
      getAssistencias : function () {
         let ref = this;
			this.$store.dispatch('alternarTelaCarregamento', true)
         this.resultado.pesquisa = []

			this.$axios({
				method: 'get',
				url: this.urlRest +'site/getAssistencias',
            params: {
               estado: this.pesquisa.estado,
               ibge: this.pesquisa.cidade != null ? this.pesquisa.cidade.ibge : null
            }
			}).then(response => {
            this.resultado.pesquisa = response.data
            document.querySelector(".result__map__area").scrollIntoView();

			}).catch(function (error) {
				if (error.response != undefined) {
               ref.$toast.fire({
                  icon: 'error',
                  title: 'Erro: ' + error.response.status
               });
				} else {
					ref.$toast.fire({
						icon: 'error',
						title: error
					});
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
      }
   },
   mounted() {
      let ref = this;

      Vue.nextTick(function() {
         ref.chartOptions.chart.width = ($(".map__area .container").width() > 800 ? 800 : $(".map__area .container").width())
         ref.chartOptions.chart.height = ($(".map__area .container").width() > 800 ? 800 : $(".map__area .container").width())

         $(window).on("resize", function() {
            ref.chartOptions.chart.width = ($(".map__area .container").width() > 800 ? 800 : $(".map__area .container").width())
            ref.chartOptions.chart.height = ($(".map__area .container").width() > 800 ? 800 : $(".map__area .container").width())
         });
      }.bind(this));
   }
}

</script>

<style scoped>

* {
   -webkit-touch-callout: none; /* iOS Safari */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Old versions of Firefox */
   -ms-user-select: none; /* Internet Explorer/Edge */
   user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

</style>